<template>
    <el-dialog title="导入文件" :close-on-click-modal="false" width="60%" append-to-body :visible.sync="visible"
               class="dialogTable" @close="closeForm()" v-if="visible">
        <el-steps :active="active" align-center class="m_b2">
            <el-step title="上传文件" description="根据要求上传文件"></el-step>
            <el-step title="预览文件" description="预览上传文件内容">2</el-step>
            <el-step title="执行导入" description="数据导入至服务器">3</el-step>
            <el-step title="导入结果" description="完成数据批量导入">4</el-step>
        </el-steps>
        <div class="flex_l_c b_d" v-show="active == 0" v-loading="loading">
            <img src="@/assets/img/zipIcon.png" class="zipIcon">
            <div class="uploadTxt b_l flex1">
                <div>上传档案挂接附件压缩文件</div>
                <div class="tips">请上传档案附件压缩文件，文件格式为rar、zip，压缩文件内每个文件均需填写好对应档案号</div>
                <resume-upload upDateType="fileLinkage" dir="archives" v-show="!fileInfo.name" ref="resume" filtButtonName="上传文件"
                               :filtButton="true" :allowFilesShow="false" :uploadNum="1" :allowFiles="fileType"
                               @upFileDate="(data)=>getUpData(data)"></resume-upload>
                <div class="flex_l_c" v-if="fileInfo.name">
                    <div class="name omit">{{fileInfo.name}}</div>
                    <el-button type="text" size="small" @click="delFile()">删除</el-button>
                </div>
            </div>
        </div>
        <el-table :data="fileList" size="small" class="table" v-show="active == 1">
            <el-table-column type="index" label="序号"></el-table-column>
            <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip></el-table-column>
            <el-table-column prop="reference" label="档号（文件目录号）" show-overflow-tooltip></el-table-column>
            <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
            <el-table-column prop="fileSize" label="文件大小（KB）" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="p_all2" v-show="active == 2">
            <div class="flex_b_c m_b1">
                <div>数据上传中，请勿中断操作！</div>
                <el-button type="text" size="small" @click="cancelUpload()">取消上传</el-button>
            </div>
            <el-progress :text-inside="true" :stroke-width="26" :percentage="progress"></el-progress>
        </div>
        <div v-show="active == 3">
            <div class="m_b1">完成档案挂接！成功挂接 <span class="greenColor">{{successCount}}</span> 个档案文件，失败 <span
                    class="redColor">{{failureCount}}</span> 个档案文件。
            </div>
            <el-table :data="resultList" size="small" class="table">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="fileName" label="文件名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="reference" label="档号（文件目录号）" show-overflow-tooltip></el-table-column>
                <el-table-column prop="fileFormat" label="文件格式" show-overflow-tooltip></el-table-column>
                <el-table-column prop="fileSize" label="文件大小（KB）" show-overflow-tooltip></el-table-column>
                <el-table-column prop="result" label="文件挂接结果" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div class="greenColor" v-if="scope.row.result">成功</div>
                        <div class="redColor" v-else>失败</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination v-show="active == 1 || active == 3"
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="searchForm.current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="searchForm.size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="prev" size="small" v-if="active == 1" :disabled="loading">上一步</el-button>
                <el-button type="primary" @click="next" size="small" v-if="active < 2" :disabled="loading">下一步</el-button>
                <el-button type="primary" @click="confirm" size="small" v-if="active == 3">完成</el-button>
            </span>
    </el-dialog>
</template>

<script>
    import ResumeUpload from "@/components/Upload/resumeUpload.vue";

    export default {
        components: {
            ResumeUpload
        },
        data() {
            return {
                loading: false,
                visible: false,
                active: 0,
                fileType: ['RAR', 'ZIP'],
                fileInfo: {},
                fileList: [],
                resultList: [],
                fileZipId: '',
                searchForm: {
                    current: 1,
                    size: 10,
                },
                total: 0,
                progress: 0,
                timer: null,
                failureCount: 0,
                successCount: 0,
            }
        },
        methods: {
            init() {
                this.visible = true
            },
            // 上传文件
            getUpData(data) {
                if (data.length) {
                    this.fileInfo = data[0]
                }
            },
            // 预览文件
            zipExtractor() {
                this.loading = true
                this.$axios(this.api.record.zipExtractor, {filePath: this.fileInfo.upDate.filePath}).then((res) => {
                    if (res.status) {
                        this.fileZipId = res.data
                        this.active++
                        this.executeImportList(1)
                    } else {
                        this.$message.error('预览文件失败');
                    }
                    this.loading = false
                })
            },
            // 删除文件
            delFile() {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$refs["resume"].deleteFile(this.fileInfo.id)
                    this.fileInfo = {}
                })

            },
            // 取消上传
            cancelUpload() {
                const h = this.$createElement;
                this.$msgbox({
                    title: '提示',
                    message: h('p', null, [
                        h('p', {style: 'color: #000000; margin-bottom: 5px'}, '正在导入数据至服务器，是否中断操作？'),
                        h('p', {style: 'color: #999999; font-size: 12px'}, '中断后将取消本次导入的全部数据')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    this.closeForm()
                });
            },
            // 上一步
            prev() {
                this.active--
            },
            // 下一步
            next() {
                if (this.active == 0 && !this.fileInfo.name) {
                    this.$message('请上传文件');
                } else if (this.active == 0) {
                    this.zipExtractor()
                } else if (this.active == 1) {
                    this.active++
                    this.executeImport()
                } else if (this.active == 2) {
                    this.active++
                } else {
                    this.active++
                }
            },
            // 执行导入
            executeImport() {
                this.loading = true
                this.$axios(this.api.record.executeImport, {fileZipId: this.fileZipId}).then((res) => {
                    if (res.status) {
                        this.executeImportList(2)
                        this.getArchivesExecuteimport()
                        this.timer = setInterval(() => {
                            if (this.progress < 95) {
                                this.progress++
                            } else {
                                clearInterval(this.timer)
                            }
                        }, 30)
                    }
                })
            },
            // 结果列表
            executeImportList(type) {
                console.log(this.active);
                this.loading = true
                if(type) {
                    this.searchForm.current = 1
                }
                this.$axios(this.api.record.archivesexecuteimport, {fileZipId: this.fileZipId, ...this.searchForm}).then((res) => {
                    if (res.status) {
                        if(this.active == 1) {
                            this.fileList = res.data.records
                        }
                        if(type == 2) {
                            this.active++
                        }
                        if(this.active == 3) {
                            this.resultList = res.data.records
                            console.log(this.resultList);
                        }
                        console.log(this.active);
                        this.total = parseInt(res.data.total)
                    }
                    this.loading = false
                })
            },
            // 结果统计
            getArchivesExecuteimport() {
                this.$axios(this.api.record.getArchivesExecuteimport, {fileZipId: this.fileZipId}).then((res) => {
                    if (res.status) {
                        this.failureCount = res.data.failureCount
                        this.successCount = res.data.successCount
                    }
                })
            },
            // 完成
            confirm() {
                this.closeForm();
                this.$emit('refreshDataList')
            },
            closeForm() {
                this.active = 0
                this.fileInfo = {}
                this.fileList = []
                this.visible = false
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.executeImportList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.executeImportList()
            },
        }
    }
</script>

<style scoped>
    .zipIcon {
        width: 80px;
        padding: 10px;
    }

    .uploadTxt {
        padding: 10px 20px;
    }

    .tips {
        font-size: 12px;
        color: #999999;
        padding: 4px 0 12px;
    }

    .name {
        width: 400px;
        margin-right: 20px;
    }
</style>
